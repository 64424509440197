.home-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.home-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  margin-right: var(--dl-space-space-unit);
  border-radius: 35px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgb(231, 219, 253);
}
.home-text10 {
  color: rgb(0, 0, 0);
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: justify;
  font-family: "Open Sans";
  font-weight: 300;
  margin-left: var(--dl-space-space-oneandhalfunits);
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  margin-right: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-text11 {
  font-size: 20px;
}
.home-text15 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link10 {
  text-decoration: underline;
}
.home-text17 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text18 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text20 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text21 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link11 {
  text-decoration: underline;
}
.home-text22 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link12 {
  text-decoration: underline;
}
.home-text23 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link13 {
  text-decoration: underline;
}
.home-text24 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link14 {
  text-decoration: underline;
}
.home-text25 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link15 {
  text-decoration: underline;
}
.home-text26 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text29 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text30 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link16 {
  text-decoration: underline;
}
.home-text31 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link17 {
  text-decoration: underline;
}
.home-text32 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link18 {
  text-decoration: underline;
}
.home-text33 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link19 {
  text-decoration: underline;
}
.home-text34 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text37 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text38 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link20 {
  text-decoration: underline;
}
.home-text39 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link21 {
  text-decoration: underline;
}
.home-text40 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link22 {
  text-decoration: underline;
}
.home-text41 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text44 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text45 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link23 {
  text-decoration: underline;
}
.home-text46 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link24 {
  text-decoration: underline;
}
.home-text47 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link25 {
  text-decoration: underline;
}
.home-text48 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link26 {
  text-decoration: underline;
}
.home-text49 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text50 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-text52 {
  color: #000000;
  font-family: "TTNorms-Regular";
  text-decoration: underline;
}
.home-link27 {
  text-decoration: underline;
}
.home-link28 {
  display: contents;
}
.home-image {
  width: 200px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-container1 {
    background-color: var(--dl-color-gray-white);
  }
  .home-container2 {
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .home-container1 {
    background-color: var(--dl-color-gray-white);
  }
}
@media(max-width: 479px) {
  .home-container1 {
    background-color: var(--dl-color-gray-white);
  }
  .home-container2 {
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-image {
    border-radius: var(--dl-radius-radius-radius8);
  }
}
